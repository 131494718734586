import { Avatar, Box } from '@mui/material';
import { motion } from 'framer-motion';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useScrollToBottom } from 'react-scroll-to-bottom';
import { Cursor, useTypewriter } from 'react-simple-typewriter';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import ePulseAIAvatar from '../Assets/Images/EPulseAI_Avatar.svg';
import UserAvatar from '../Assets/Images/User.svg';
import useLoggedInUser from '../Hooks/useLoggedInUser';
import ChartComponent from './Chart';
import DataTable from './DataTable';

const BoxMotion = motion(Box);

const TYPE_SPEED = 1;

const ChatMessage = memo(({ message, showSql }) => {
  const [cursorVisible, setCursorVisible] = useState('|');
  const { loggedInUser } = useLoggedInUser();
  const [profilePicUrl] = useState(loggedInUser.profilePicUrl);
  const scrollToBottom = useScrollToBottom();
  const [typeSpeed] = useState(() => {
    const storageSpeed = parseInt(
      localStorage.getItem('datacopilot.typingspeed'),
      10,
    );

    if (typeof storageSpeed === 'number' && storageSpeed >= 0) {
      return storageSpeed;
    }

    return TYPE_SPEED;
  });

  const [text, helper] = useTypewriter({
    words: [message.message],
    typeSpeed,
    onType: () => {
      scrollToBottom({
        behavior: 'smooth',
      });
    },
  });

  const { isDone } = helper;

  useEffect(() => {
    if (isDone === true) {
      // Typewriter is done, hide the cursor, show copy button and scroll to bottom
      setCursorVisible('');
      scrollToBottom({
        behavior: 'smooth',
      });
    } else {
      // Typewriter is still typing, show the cursor and hide copy button
      setCursorVisible('|');
    }
  }, [isDone, scrollToBottom]);

  const getAvatarSrc = useMemo(() => {
    if (message.user !== 'me') return ePulseAIAvatar;

    return profilePicUrl === 'undefined' ||
      profilePicUrl === null ||
      profilePicUrl === ''
      ? UserAvatar
      : ePulseAIAvatar;
  }, [message.user, profilePicUrl]);

  return (
    <BoxMotion
      alignItems="center"
      animate={{ opacity: 1 }}
      bgcolor={message.user === 'gpt' ? '#FFF' : '#EEEEEE'}
      borderRadius={0}
      display="flex"
      initial={{ opacity: 0.5 }}
      paddingX="1.5rem"
      paddingY="1.5rem"
      transition={{ duration: 0.1 }}
      width="100%"
    >
      <Avatar
        src={getAvatarSrc}
        sx={{
          alignSelf: 'flex-start',
          height: 60,
          width: 60,
        }}
        variant="square"
      />

      {message.user === 'me' && (
        <Box
          display="flex"
          flexDirection="column"
          id="user-message"
          paddingX="1rem"
          sx={{ whiteSpace: 'pre-wrap' }}
          width="100%"
        >
          {message.message.split('\n')}
        </Box>
      )}

      {message.user === 'gpt' && (
        <Box
          data-testid="ai-message"
          display="flex"
          flexDirection="column"
          id="user-message"
          paddingX="1rem"
          sx={{
            whiteSpace: 'pre-wrap',
            overflowX: 'auto',
          }}
          width="100%"
        >
          {message.resultType.trim().toLowerCase() === 'chart' &&
            message.data.length > 1 && (
              <ChartComponent data={message.data} visible />
            )}
          {message.resultType.trim().toLowerCase() === 'table' &&
            message.data.length > 0 && (
              <DataTable data={message.data} visible />
            )}
          {text.trimStart()}
          {showSql && message.sql && (
            <Box mt={2}>
              <SyntaxHighlighter language="sql" style={atomDark}>
                {message.sql}
              </SyntaxHighlighter>
            </Box>
          )}
          <Cursor cursorColor="white" cursorStyle={cursorVisible} />
        </Box>
      )}
    </BoxMotion>
  );
});
ChatMessage.displayName = 'ChatMessage';

export default ChatMessage;
